import NavBar from "./components/NavBar";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HeroSection from "./components/HeroSection";
import "./style/bottomNavigation.css";
import OurBestSeller from "./components/OurBestSeller";
import BottomNav from "./components/BottomNav";
import BanglaLine from "./components/BanglaLine";
import MeetOurFounder from "./components/MeetOurFounder";
import FooterSection from "./components/FooterSection";
import Menu from "./components/Menu";
import Catering from "./components/Catering";
import AboutUs from "./components/AboutUs";
import FloorAndLounges from "./components/FloorAndLounges";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Disclaimer from "./components/Disclaimer";
import Awards from "./components/Awards";
import SiteMap from "./components/SiteMap";
import Location from "./components/Location";
import NotFound from "./components/NotFound";

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <BanglaLine />
                <HeroSection />
                <OurBestSeller />
                <FloorAndLounges />
                <MeetOurFounder />
              </>
            }
          />
          <Route
            path="/menu"
            element={
              <>
                <Menu />
              </>
            }
          />
          <Route
            path="/catering"
            element={
              <>
                <Catering />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <AboutUs />
              </>
            }
          />
          <Route
            path="/termsandconditions"
            element={
              <>
                <TermsAndConditions />
              </>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <>
                <PrivacyPolicy />
              </>
            }
          />
          <Route
            path="/disclaimer"
            element={
              <>
                <Disclaimer />
              </>
            }
          />
          <Route
            path="/awards"
            element={
              <>
                <Awards />
              </>
            }
          />

          <Route
            path="/sitemap"
            element={
              <>
                <SiteMap />
              </>
            }
          />

          <Route
            path="/location"
            element={
              <>
                <Location />
              </>
            }
          />

          <Route
            path="*"
            element={
              <>
                <NotFound />
              </>
            }
          />
        </Routes>
        <FooterSection />
        <BottomNav />
      </Router>
    </>
  );
}

export default App;
