import React from "react";
import best4 from "../assets/best4.jpg";
import "../style/bestSeller.css";

function BestSeller4() {
  return (
    <div>
      <img src={best4} alt="best seller images" className="best" />
    </div>
  );
}

export default BestSeller4;
