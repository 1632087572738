import React from "react";
import best8 from "../assets/best8.jpg";
import "../style/bestSeller.css";

function BestSeller8() {
  return (
    <div>
      <img src={best8} alt="best seller images" className="best" />
    </div>
  );
}

export default BestSeller8;
