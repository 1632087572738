import React from "react";
import { Card } from "antd";
const { Meta } = Card;

const AwardsSingle = ({ content }) => (
  <Card
    style={{
      width: 300,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}
    cover={
      <img
        alt="example"
        src={content.img}
        style={{
          height: "250px",
          width: "100%",
          paddingInline: "40px",
          paddingTop: "15px",
        }}
      />
    }
    actions={[<div style={{ paddingInline: "18px" }}></div>]}
  >
    <Meta title={content.name} description={content.description} />
  </Card>
);
export default AwardsSingle;
