import React from "react";
import "../style/location.css";
import dadaboudifront from "../assets/dadaboudi-front.jpg";
import dadaboudireataurant from "../assets/dadaboudi-restaurant.jpg";
import dadaboudisodepur from "../assets/dadaboudi-soadpur.jpg";
import dadaboudilogo from "../assets/dadaboudi-logo.png";

const Location = () => {
  return (
    <div className="menu-container">
      <div className="side-border left"></div>
      <div className="side-border right"></div>
      <div className="menu-header">
        <h1>Dada Boudi all locations</h1>
      </div>

      <div className="restaurant-info-section">
        <div className="restaurant-info-item">
          <img
            src={dadaboudifront}
            alt="Restaurant"
            className="restaurant-photo"
          />
          <div className="restaurant-details">
            <h2 className="restaurant-name">Dada Boudi Hotel</h2>
            <p className="restaurant-address">
              <span style={{ fontWeight: "bold" }}>Address :</span>
              1, Ghoshpara Rd, Barrackpore, West Bengal 700120
            </p>
            <p className="restaurant-address">
              <span style={{ fontWeight: "bold" }}>contact us :</span>
              <a
                href={`tel:8100510647`}
                style={{ color: "black", textDecoration: "none" }}
              >
                8100510647
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="restaurant-info-section">
        <div className="restaurant-info-item">
          <img
            src={dadaboudireataurant}
            alt="Restaurant"
            className="restaurant-photo"
          />
          <div className="restaurant-details">
            <h2 className="restaurant-name">Dada Boudi Restaurant</h2>
            <p className="restaurant-address">
              <span style={{ fontWeight: "bold" }}>Address :</span>
              12, 10, SN Banerjee Rd, Barrackpore, West Bengal 700120
            </p>
            <p className="restaurant-address">
              <span style={{ fontWeight: "bold" }}>contact us :</span>{" "}
              <a
                href={`tel:8017099986`}
                style={{ color: "black", textDecoration: "none" }}
              >
                8017099986
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="restaurant-info-section">
        <div className="restaurant-info-item">
          <img
            src={dadaboudisodepur}
            alt="Restaurant"
            className="restaurant-photo"
          />
          <div className="restaurant-details">
            <h2 className="restaurant-name">Dada Boudi Sodepur Restaurant</h2>
            <p className="restaurant-address">
              <span style={{ fontWeight: "bold" }}>Address :</span>
              33, Barrackpore Trunk Rd, Kamarhati, Sodepur, Khardaha, West
              Bengal 700117
            </p>
            <p className="restaurant-address">
              <span style={{ fontWeight: "bold" }}>contact us :</span>{" "}
              <a
                href={`tel:9748528886`}
                style={{ color: "black", textDecoration: "none" }}
              >
                9748528886
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="restaurant-info-section">
        <div className="restaurant-info-item">
          <img
            src={dadaboudilogo}
            alt="Restaurant"
            className="restaurant-photo"
          />
          <div className="restaurant-details">
            <h2 className="restaurant-name">
              Dada Boudi Madhyamgram Restaurant (Opening this puja 2024...)
            </h2>
            <p className="restaurant-address">
              <span style={{ fontWeight: "bold" }}>Address :</span>Updating
              Soon...
            </p>
            <p className="restaurant-address">
              <span style={{ fontWeight: "bold" }}>contact us :</span> Updating
              Soon...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
