import React, { useState } from "react";
import "../style/navBar.css";
import logo from "../assets/dadaboudi-logo.png";
import { Button } from "antd-mobile";
import { Link } from "react-router-dom";
import { Modal } from "antd";

function NavBar() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const phoneNumber = "8100510647";

  return (
    <header style={{ maxWidth: "100%" }}>
      <Link to="/">
        <img src={logo} alt="dada boudi logo" className="nav-img" />
      </Link>

      <nav>
        <Link style={{ fontSize: "16px" }} to="/" className="nav-item nav-home">
          Home
        </Link>
        <Link
          style={{ fontSize: "16px" }}
          to="/menu"
          className="nav-item nav-menus"
        >
          Menus
        </Link>
        <Link
          style={{ fontSize: "16px" }}
          to="/catering"
          className="nav-item nav-catering"
        >
          Catering
        </Link>
        <Link
          style={{ fontSize: "16px" }}
          to="/about"
          className="nav-item nav-story"
        >
          Our Story
        </Link>
      </nav>
      <Button
        fill="solid"
        shape="rounded"
        style={{ backgroundColor: "#8a0221", color: "white" }}
        className="btn"
        onClick={showModal}
      >
        Enquiry
      </Button>

      <Modal
        title="Enquiry Number"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "24px",
            color: "blue",
            cursor: "pointer",
            marginBottom: "20px",
          }}
        >
          <a
            href={`tel:${phoneNumber}`}
            style={{ color: "black", textDecoration: "none" }}
          >
            {phoneNumber}
          </a>
        </p>
      </Modal>
    </header>
  );
}

export default NavBar;
