export const OurSignatureDish = [
  {
    type: "Starter",
    itemDetails: [
      { name: "CHEESE GARLIC FISH TIKKA", price: "400" },
      { name: "ORIGINAL VETKI (DIAMOND) FISH FRY", price: "180" },
      { name: "GOLDEN FRIED PRAWNS (2PCS)", price: "180" },
      { name: "CHICKEN KALMI KABAB [ 2PCS ]", price: "160" },
      { name: "CHICKEN KALMI KABAB [ 4PCS ]", price: "280" },
      { name: "PUNJABI TANGRI [ 2PCS ] ", price: "200" },
      { name: "PUNJABI TANGRI [ 4PCS ] ", price: "380" },
      { name: "THAI FISH", price: "300" },
      { name: "BUTTER GARLIC PRAWNS (4PCS)", price: "370" },
      { name: "CHEESE STUFFED MUSHROOM", price: "260" },
      { name: "AMERICAN CRISPY CORN", price: "200" },
    ],
  },
  {
    type: "Chinese Dish",
    itemDetails: [
      { name: "CANTONESE NOODLES (VEG)", price: "190" },
      { name: "CANTONESE NOODLES (EGG)", price: "210" },
      { name: "CANTONESE NOODLES (CHICKEN)", price: "240" },
      { name: "CANTONESE NOODLES (MIX)", price: "260" },
      { name: "THAI CHILLI BASIL (VEG)", price: "250" },
      { name: "THAI CHILLI BASIL (MIX)", price: "320" },
      { name: "CHEESE CHILI GARLIC NAAN", price: "120" },
      { name: "LEMON GRASS FRIED RICE (VEG)", price: "170" },
      { name: "LEMON GRASS FRIED RICE (EGG)", price: "190" },
      { name: "LEMON GRASS FRIED RICE (CHICKEN)", price: "220" },
      { name: "LEMON GRASS FRIED RICE (MIX)", price: "260" },
    ],
  },
  {
    type: "Gravy",
    itemDetails: [
      { name: "MUMTAZ CHICKEN (4PCS)", price: "399" },
      { name: "BADSHAHI MURG (4PCS)", price: "350" },
      { name: "MUTTON BHUNA GHOST (4PCS)", price: "330" },
      { name: "PRAWN IN CHILLI LEMON SAUCE (4PCS)", price: "380" },
    ],
  },
  {
    type: "Mocktail",
    itemDetails: [
      { name: "ROYAL FROSE", price: "189" },
      { name: "TOMAKE CHAI", price: "190" },
    ],
  },
  {
    type: "Desert",
    itemDetails: [
      { name: "FIRNI", price: "120" },
      { name: "RAJASTHANI KULFI", price: "140" },
      { name: "COCONUT TENDER", price: "140" },
      { name: "NALEN GUR ICE-CREAM", price: "140" },
      { name: "DADA BOUDI SPECIAL FALUDA", price: "190" },
    ],
  },
];

export const ChineseDish = [
  {
    type: "Soup",
    itemDetails: [
      { name: "MANCHOW SOUP (VEG)", price: "90" },
      { name: "MANCHOW SOUP (NON-VEG)", price: "120" },
      { name: "SWEET CORN SOUP (VEG)", price: "90" },
      { name: "SWEET CORN SOUP (NON-VEG)", price: "120" },
      { name: "HOT & SOUR SOUP (VEG)", price: "90" },
      { name: "HOT & SOUR SOUP (NON-VEG)", price: "120" },
      { name: "LAKSA SOUP (VEG)", price: "150" },
      { name: "LAKSA SOUP (NON-VEG)", price: "180" },
      { name: "TOM KHA SOUP (VEG/NON-VEG)", price: "130" },
      { name: "TOM KHA SOUP (VEG/NON-VEG)", price: "160" },
    ],
  },
  {
    type: "Dim Sum",
    itemDetails: [
      { name: "CHICKEN XIAO LONG BAO DIM SUM (6PCS)", price: "200" },
    ],
  },
  {
    type: "Chinese Starter (Veg)",
    itemDetails: [
      { name: "CRISPY CHILLI BABYCORN", price: "200" },
      { name: "PANEER CHILLI DRY", price: "200" },
      { name: "AMERICAN CRISPY CORN", price: "200" },
      { name: "VEG MANCHURIAN DRY", price: "200" },
      { name: "CHEESE STUFFED MUSHROOM", price: "260" },
      { name: "CHILLI MUSHROOM DRY", price: "210" },
    ],
  },
  {
    type: "Chinese Starter (Chicken)",
    itemDetails: [
      { name: "CHILLI CHICKEN DRY", price: "250" },
      { name: "CRISPY CHICKEN", price: "230" },
      { name: "DRUMS OF HEAVEN", price: "250" },
      { name: "FIRE OF WINGS", price: "225" },
      { name: "CHICKEN 65", price: "250" },
      { name: "BUTTER GARLIC (CHICKEN)", price: "250" },
      { name: "PAN FRIED CHICKEN", price: "220" },
      { name: "CHILLI BASIL TOASTED CHICKEN", price: "220" },
    ],
  },
  {
    type: "Chinese Starter (Fish)",
    itemDetails: [
      { name: "ORIGINAL VETKI (DIAMOND) FISH FRY", price: "190" },
      { name: "GOLDEN FRIED PRAWNS (2PCS)", price: "180" },
      { name: "THAI FISH", price: "300" },
      { name: "BUTTER GARLIC PRAWNS", price: "370" },
      { name: "CRISPY FISH", price: "295" },
      { name: "FISH WITH HONEY MUSTARD SAUCE", price: "300" },
      { name: "PAN FRY CHILLI FISH", price: "295" },
      { name: "SQUID WITH THAI SAUCE", price: "320" },
      { name: "SQUID SALT AND PAPPER", price: "340" },
      { name: "SQUID CHILLI DRY", price: "320" },
    ],
  },
  {
    type: "Chinese Main Course (Veg)",
    itemDetails: [
      { name: "VEG MANCHURIAN GRAVY", price: "240" },
      { name: "CHILLI PANEER GRAVY", price: "240" },
      { name: "PANEER MANCHURIAN GRAVY", price: "240" },
      { name: "CHILLI MUSHROOM GRAVY", price: "240" },
    ],
  },
  {
    type: "Chinese Main Course (Non-Veg)",
    itemDetails: [
      { name: "CHILLI GRAVY [CHICKEN]", price: "260" },
      { name: "CHILLI GRAVY [FISH]", price: "310" },
      { name: "MANCHURIAN GRAVY [CHICKEN]", price: "260" },
      { name: "MANCHURIAN GRAVY [FISH]", price: "310" },
      { name: "GARLIC GRAVY [CHICKEN]", price: "260" },
      { name: "GARLIC GRAVY [FISH]", price: "310" },
      { name: "PRAWN IN CHILLI LEMON SAUCE", price: "380" },
      { name: "SWEET & SOUR GRAVY [CHICKEN]", price: "260" },
      { name: "SWEET & SOUR GRAVY [FISH]", price: "310" },
      { name: "SCHEZWAN GRAVY [CHICKEN]", price: "280" },
      { name: "SCHEZWAN GRAVY [FISH]", price: "330" },
      { name: "CHILLI PRAWNS GRAVY", price: "370" },
    ],
  },
  {
    type: "Noodles",
    itemDetails: [
      { name: "HAKKA NOODLES (VEG)", price: "150" },
      { name: "HAKKA NOODLES (EGG)", price: "170" },
      { name: "HAKKA NOODLES (CHICKEN)", price: "200" },
      { name: "HAKKA NOODLES (MIX)", price: "210" },
      { name: "CHOWMEIN (GRAVY) (VEG)", price: "170" },
      { name: "CHOWMEIN (GRAVY) (EGG)", price: "190" },
      { name: "CHOWMEIN (GRAVY) (CHICKEN)", price: "220" },
      { name: "CHOWMEIN (GRAVY) (MIX)", price: "230" },
      { name: "SINGAPUR NOODLES (VEG)", price: "170" },
      { name: "SINGAPUR NOODLES (EGG)", price: "190" },
      { name: "SINGAPUR NOODLES (CHICKEN)", price: "220" },
      { name: "SINGAPUR NOODLES (MIX)", price: "250" },
      { name: "CANTONESE NOODLES [GRAVY] (VEG)", price: "190" },
      { name: "CANTONESE NOODLES [GRAVY] (EGG)", price: "210" },
      { name: "CANTONESE NOODLES [GRAVY] (CHICKEN)", price: "240" },
      { name: "CANTONESE NOODLES [GRAVY] (MIX)", price: "260" },
      { name: "THAI CHILLI BASIL NOODLES (VEG)", price: "250" },
      { name: "THAI CHILLI BASIL NOODLES (MIX)", price: "320" },
      { name: "SCHEZWAN NOODLES (VEG)", price: "160" },
      { name: "SCHEZWAN NOODLES (EGG)", price: "180" },
      { name: "SCHEZWAN NOODLES (CHICKEN)", price: "210" },
      { name: "SCHEZWAN NOODLES (MIX)", price: "240" },
      { name: "CHILLI GARLIC NOODLES (VEG)", price: "160" },
      { name: "CHILLI GARLIC NOODLES (EGG)", price: "180" },
      { name: "CHILLI GARLIC NOODLES (CHICKEN)", price: "210" },
      { name: "CHILLI GARLIC NOODLES (MIX)", price: "240" },
    ],
  },
  {
    type: "Chinese Rice",
    itemDetails: [
      { name: "FRIED RICE (VEG)", price: "140" },
      { name: "FRIED RICE (EGG)", price: "160" },
      { name: "FRIED RICE (CHICKEN)", price: "190" },
      { name: "FRIED RICE (MIX)", price: "210" },
      { name: "LEMON GREEN FRIED RICE (VEG)", price: "170" },
      { name: "LEMON GREEN FRIED RICE (EGG)", price: "190" },
      { name: "LEMON GREEN FRIED RICE (CHICKEN)", price: "220" },
      { name: "LEMON GREEN FRIED RICE (MIX)", price: "260" },
      { name: "JAPANESE RICE BOWL (VEG)", price: "250" },
      { name: "JAPANESE RICE BOWL (MIX)", price: "320" },
      { name: "HUNAN RICE (VEG)", price: "190" },
      { name: "HUNAN RICE (EGG)", price: "220" },
      { name: "HUNAN RICE (CHICKEN)", price: "240" },
      { name: "HUNAN RICE (MIX)", price: "270" },
      { name: "SCHEZWAN RICE (VEG)", price: "150" },
      { name: "SCHEZWAN RICE (EGG)", price: "170" },
      { name: "SCHEZWAN RICE (CHICKEN)", price: "210" },
      { name: "SCHEZWAN RICE (MIX)", price: "230" },
    ],
  },
];

export const Biriyani = [
  {
    type: "Biriyani",
    itemDetails: [
      { name: "MUTTON BIRIYANI", price: "320*" },
      { name: "CHICKEN BIRIYANI", price: "230*" },
      { name: "ALU BIRIYANI", price: "160*" },
      { name: "SPECIAL MUTTON BIRIYANI", price: "590" },
      { name: "SPECIAL CHICKEN BIRIYANI", price: "430" },
      { name: "EXTRA MUTTON", price: "230" },
      { name: "EXTRA CHICKEN", price: "160" },
    ],
  },
];

export const Salad = [
  {
    type: "Salad",
    itemDetails: [
      { name: "GREEN SALAD", price: "70" },
      { name: "ONION SALAD", price: "50" },
      { name: "RAITA", price: "80" },
    ],
  },
];

export const IndianDish = [
  {
    type: "Rice",
    itemDetails: [
      { name: "STEAM RICE", price: "100" },
      { name: "PEAS PULAO", price: "170" },
      { name: "JEERA RICE", price: "140" },
      { name: "VEG PULAO", price: "190" },
      { name: "KASHMIRI PULAO", price: "220" },
    ],
  },
  {
    type: "Indian Bread",
    itemDetails: [
      { name: "CHEESE CHILLI GARLIC NAAN", price: "120" },
      { name: "TANDOORI ROTI (PLAIN)", price: "35" },
      { name: "TANDOORI ROTI (BUTTER)", price: "45" },
      { name: "NAAN (PLAIN)", price: "50" },
      { name: "NAAN (BUTTER)", price: "60" },
      { name: "NAAN (GARLIC)", price: "80" },
      { name: "MASALA KULCHA", price: "90" },
      { name: "LACHHA PARATHA", price: "60" },
      { name: "KASHMIRI NAAN", price: "90" },
    ],
  },
  {
    type: "Indian Main Course (Veg)",
    itemDetails: [
      { name: "PANEER LAJAWAB", price: "240" },
      { name: "NAVRATAN KORMA", price: "210" },
      { name: "DAL MAKHANI", price: "180" },
      { name: "MIXED VEGETABLE", price: "200" },
      { name: "YELLOW DAL FRY", price: "120" },
      { name: "PANEER BUTTER MASALA", price: "210" },
      { name: "KADHAI PANEER", price: "210" },
      { name: "MUSHROOM MATAR MASALA", price: "220" },
      { name: "PANEER TIKKA MASALA", price: "240" },
      { name: "SAHI PANEER", price: "220" },
      { name: "MALAI KOFTA", price: "220" },
      { name: "PANEER PASINDA", price: "240" },
      { name: "MATAR PANEER", price: "220" },
    ],
  },
  {
    type: "Indian Main Course (Chicken)",
    itemDetails: [
      { name: "CHICKEN CHAP", price: "180" },
      { name: "CHICKEN MAHARANI", price: "230" },
      { name: "MUMTAZ CHICKEN (4PCS)", price: "400" },
      { name: "BADSHAHI MURG (4PCS)", price: "360" },
      { name: "BUTTER CHICKEN", price: "300" },
      { name: "CHICKEN TIKKA BUTTER MASALA", price: "320" },
      { name: "CHICKEN BHARTA", price: "240" },
      { name: "CHICKEN LABABDAR", price: "280" },
      { name: "CHICKEN RESHMI BUTTER MASALA", price: "330" },
      { name: "KADHAI CHIKEN", price: "300" },
      { name: "CHICKEN TIKKA MASALA", price: "300" },
    ],
  },
  {
    type: "Indian Main Course (Fish)",
    itemDetails: [
      { name: "FISH TIKKA MASALA (VETKI)", price: "440" },
      { name: "FISH DOPYAZA", price: "300" },
      { name: "FISH CURRY", price: "250" },
      { name: "FISH MASALA", price: "300" },
    ],
  },
  {
    type: "Indian Main Course (Mutton)",
    itemDetails: [
      { name: "MUTTON CHAP", price: "280" },
      { name: "MUTTON LIVER FRY", price: "260" },
      { name: "MUTTON KASA", price: "260" },
      { name: "MUTTON BHUNA GHOST", price: "330" },
      { name: "MUTTON KEEMA", price: "260" },
    ],
  },
];

export const TandoorDish = [
  {
    type: "Tandoor Starters (VEG)",
    itemDetails: [
      { name: "PANEER TIKKA", price: "190" },
      { name: "PANEER MALAI TIKKA", price: "210" },
      { name: "MUSHROOM TIKKA (AS PER SIZE /PCS)", price: "200" },
    ],
  },
  {
    type: "Tandoor Starters (NON-VEG)",
    itemDetails: [
      { name: "CHEESE GARLIC FISH TIKKA", price: "400" },
      { name: "FISH TIKKA (VETKI-4PCS)", price: "380" },
      { name: "CHICKEN TANDOORI FULL (4PCS)", price: "400" },
      { name: "CHICKEN TANDOORI HALF (2PCS)", price: "220" },
      { name: "CHICKEN RESHMI KABAB (6PCS)", price: "260" },
      { name: "CHICKEN VATIYALI KABAB (6PCS)", price: "260" },
      { name: "CHICKEN KALMI KABAB (4PCS)", price: "320" },
      { name: "CHICKEN KALMI KABAB (2PCS)", price: "170" },
      { name: "PUNJABI TANGRI (4PCS)", price: "380" },
      { name: "PUNJABI TANGRI (2PCS)", price: "200" },
      { name: "CHICKEN TIKKA KABAB (6PCS)", price: "260" },
      { name: "CHICKEN MALAI TIKKA (6PCS)", price: "300" },
      { name: "CHICKEN TANGRI KABAB FULL (4PCS)", price: "330" },
      { name: "CHICKEN TANGRI KABAB HALF (2PCS)", price: "200" },
      { name: "TANDOORI CHICKEN LOLIPOP (6PCS)", price: "290" },
    ],
  },
];

export const DrinksDesserts = [
  {
    type: "Mocktail",
    itemDetails: [
      { name: "TRIPLE TOWER", price: "250" },
      { name: "TOMAKE CHAI", price: "190" },
      { name: "ROYAL FROSE", price: "180" },
      { name: "THUNDER BOLT", price: "180" },
      { name: "SHIRLEY TEMPLE", price: "160" },
      { name: "KIWI PASSION", price: "180" },
      { name: "LITCHI DELIGHT", price: "160" },
      { name: "GREEN ICE-LAND", price: "160" },
      { name: "ORANGE SURPRISE", price: "160" },
      { name: "SPICY MARY", price: "160" },
      { name: "BLUE LAGOON", price: "160" },
      { name: "RAINBOW", price: "180" },
      { name: "PINEAPPLE DELIGHT", price: "160" },
      { name: "CINDERELLA", price: "160" },
    ],
  },
  {
    type: "Refreshing Drink",
    itemDetails: [
      { name: "VIRGIN MOJITO", price: "170" },
      { name: "WATERMELON MOJITO", price: "170" },
      { name: "CUCUMBER MOJITO", price: "170" },
      { name: "CLASSIC MINT ICE TEA", price: "180" },
      { name: "GINGER LEMON ICE TEA", price: "180" },
      { name: "FRESH LIME SODA", price: "100" },
      { name: "FRESH LIME WATER", price: "80" },
      { name: "MASALA COLD-DRINKS", price: "110" },
      { name: "MASALA SODA", price: "110" },
      { name: "COLD DRINKS", price: "60" },
    ],
  },
  {
    type: "Shake",
    itemDetails: [
      { name: "POPCORN CARAMEL SHAKE", price: "190" },
      { name: "OREO SHAKE", price: "180" },
      { name: "KITKAT SHAKE", price: "180" },
      { name: "STRAWBERRY SHAKE", price: "180" },
      { name: "MANGO SHAKE", price: "180" },
      { name: "BUTTERSCOTCH SHAKE", price: "180" },
      { name: "CHOCOLATE SHAKE", price: "180" },
      { name: "BERRY BLOSSOM", price: "180" },
      { name: "CLASSSIC COLD COFFEE", price: "180" },
    ],
  },
  {
    type: "Desserts",
    itemDetails: [
      { name: "DADA BOUDI SPECIAL FALUDA", price: "190" },
      { name: "NALEN GUR ICE-CREAM", price: "140" },
      { name: "COCONUT TENDER", price: "140" },
      { name: "CUSTARD APPLE ICE-CREAM", price: "140" },
      { name: "CARAMEL CRUNCH ICE-CREAM", price: "140" },
      { name: "FIRNI", price: "120" },
      { name: "RAJASTHANI KULFI", price: "120" },
      { name: "VANILA ICE-CREAM", price: "110" },
      { name: "CHOCOLATE ICE-CREAM", price: "120" },
      { name: "BUTTERSCOTCH ICE-CREAM", price: "130" },
      { name: "KESHAR PISTA ICE-CREAM", price: "140" },
    ],
  },
  {
    type: "Beverages",
    itemDetails: [{ name: "Packaged Drinking Water", price: "20" }],
  },
];
