import React from "react";

import dadaboudilogo from "../assets/dadaboudi-logo.png";
import { Avatar, Card } from "antd";

const { Meta } = Card;

const FloorAndLoungesSingleCard = ({ content }) => (
  <Card
    style={{
      width: 300,
    }}
    cover={<img alt="example" src={content.img} />}
  >
    <Meta
      avatar={<Avatar size={64} src={dadaboudilogo} />}
      title={content.name}
      description={content.description}
    />
  </Card>
);
export default FloorAndLoungesSingleCard;
