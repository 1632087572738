import React from "react";
import best7 from "../assets/best7.jpg";
import "../style/bestSeller.css";

function BestSeller7() {
  return (
    <div>
      <img src={best7} alt="best seller images" className="best" />
    </div>
  );
}

export default BestSeller7;
