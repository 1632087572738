import React from "react";
import best1 from "../assets/best1.jpg";
import "../style/bestSeller.css";

function BestSeller1() {
  return (
    <div>
      <img src={best1} alt="best seller images" className="best" />
    </div>
  );
}

export default BestSeller1;
