import React from "react";
import BanquetVideo from "./BanquetVideo";
import CateringContactIndoor from "./CateringContact";
import CateringContactOuter from "./CateringContactOuter";
// import CateringVideo from "./CateringVideo";

function Catering() {
  return (
    <>
      {/* <CateringVideo /> */}
      <BanquetVideo />
      <CateringContactIndoor />
      <CateringContactOuter />
    </>
  );
}

export default Catering;
