import React from "react";
import best5 from "../assets/best5.jpg";
import "../style/bestSeller.css";

function BestSeller5() {
  return (
    <div>
      <img src={best5} alt="best seller images" className="best" />
    </div>
  );
}

export default BestSeller5;
