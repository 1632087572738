import React from "react";
import "../style/footer.css";
import { CiFacebook } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa6";
import { AiOutlineYoutube } from "react-icons/ai";
import { Link } from "react-router-dom";

const FooterSection = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <h4>Dada Boudi Hotel</h4>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/menu">Menu</Link>
              </li>
              <li>
                <Link to="/about">Our History</Link>
              </li>
              <li>
                <Link to="/catering">Bulk Order</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Legal</h4>
            <ul>
              <li>
                <Link to="/termsandconditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacypolicy">Privecy Policy</Link>
              </li>
              <li>
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Other</h4>
            <ul>
              {/* <li>
                <Link to="/">Testimonial</Link>
              </li> */}
              <li>
                <Link to="/location">Locations</Link>
              </li>
              <li>
                <Link to="/sitemap">Site Map</Link>
              </li>
              <li>
                <Link to="/awards">Awards</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>follow us</h4>
            <div className="social-links">
              <Link
                to="https://www.instagram.com/dadaboudihotelofficial/"
                target="_blank"
              >
                <FaInstagram />
              </Link>
              <Link
                to="https://www.facebook.com/officialdadaboudihotel?mibextid=LQQJ4d"
                target="_blank"
              >
                <CiFacebook />
              </Link>
              <Link
                to="https://www.youtube.com/@DadaBoudiHotelOfficial"
                target="_blank"
              >
                <AiOutlineYoutube />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "white",
        }}
      >
        <p>
          © Copyright 2024. All Rights Reserved by DSR Janata Hindu Hotel PVT
          Ltd.
        </p>
        <p>
          made with ❤️ in Kolkata by{" "}
          <Link
            to="https://www.linkedin.com/in/sudeeproy1/"
            style={{
              backgroundColor: "white",
              color: "black",
              paddingInline: "20px",
              textDecoration: "none",
            }}
            target="_blank"
          >
            Sudeep Roy
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default FooterSection;
