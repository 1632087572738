import React from "react";
import "../style/cateringVideo.css";

const CateringVideo = () => {
  return (
    <div className="menu-container">
      <div className="side-border left"></div>
      <div className="side-border right"></div>
      <div className="menu-header">
        <h1>Customer Review</h1>
      </div>
      <div className="video-section">
        <h2>Dada Boudi Hotel</h2>
        <div className="video-wrapper">
          <iframe
            src="https://www.youtube.com/embed/8jnwmpTLh2c"
            title="YouTube video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default CateringVideo;
