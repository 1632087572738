import React from "react";
import Marquee from "react-fast-marquee";

function BanglaLine() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#8a0221",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "15px",
          paddingBottom: "15px",
        }}
      >
        <Marquee>
          <h4 style={{ color: "white", marginInline: "20px" }}>
            <span style={{ marginInline: "7px" }}>বিরিয়ানী</span>{" "}
            <span style={{ marginInline: "7px" }}>মানেই</span>{" "}
            <span style={{ marginInline: "7px" }}>দাদাবৌদি</span>{" "}
          </h4>

          <h4 style={{ color: "white", marginInline: "20px" }}>
            <span style={{ marginInline: "7px" }}>বিরিয়ানী</span>{" "}
            <span style={{ marginInline: "7px" }}>মানেই</span>{" "}
            <span style={{ marginInline: "7px" }}>দাদাবৌদি</span>{" "}
          </h4>
          <h4 style={{ color: "white", marginInline: "20px" }}>
            <span style={{ marginInline: "7px" }}>বিরিয়ানী</span>{" "}
            <span style={{ marginInline: "7px" }}>মানেই</span>{" "}
            <span style={{ marginInline: "7px" }}>দাদাবৌদি</span>{" "}
          </h4>
          <h4 style={{ color: "white", marginInline: "20px" }}>
            <span style={{ marginInline: "7px" }}>বিরিয়ানী</span>{" "}
            <span style={{ marginInline: "7px" }}>মানেই</span>{" "}
            <span style={{ marginInline: "7px" }}>দাদাবৌদি</span>{" "}
          </h4>
          <h4 style={{ color: "white", marginInline: "20px" }}>
            <span style={{ marginInline: "7px" }}>বিরিয়ানী</span>{" "}
            <span style={{ marginInline: "7px" }}>মানেই</span>{" "}
            <span style={{ marginInline: "7px" }}>দাদাবৌদি</span>{" "}
          </h4>
          <h4 style={{ color: "white", marginInline: "20px" }}>
            <span style={{ marginInline: "7px" }}>বিরিয়ানী</span>{" "}
            <span style={{ marginInline: "7px" }}>মানেই</span>{" "}
            <span style={{ marginInline: "7px" }}>দাদাবৌদি</span>{" "}
          </h4>
          <h4 style={{ color: "white", marginInline: "20px" }}>
            <span style={{ marginInline: "7px" }}>বিরিয়ানী</span>{" "}
            <span style={{ marginInline: "7px" }}>মানেই</span>{" "}
            <span style={{ marginInline: "7px" }}>দাদাবৌদি</span>{" "}
          </h4>
          <h4 style={{ color: "white", marginInline: "20px" }}>
            <span style={{ marginInline: "7px" }}>বিরিয়ানী</span>{" "}
            <span style={{ marginInline: "7px" }}>মানেই</span>{" "}
            <span style={{ marginInline: "7px" }}>দাদাবৌদি</span>{" "}
          </h4>
        </Marquee>
      </div>
    </>
  );
}

export default BanglaLine;
