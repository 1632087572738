import React from "react";
import "../style/floorAndLounges.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";
import AwardsSingle from "./AwardsSingle";

export default function Awards() {
  const ground = {
    img: "../assets/test1.jpg",
    name: "Award1",
    description: "Description1",
  };

  const first = {
    img: "../assets/test2.jpg",
    name: "Award2",
    description: "Description2",
  };

  const second = {
    img: "../assets/test3.jpg",
    name: "Award3",
    description: "Description3",
  };

  const third = {
    img: "../assets/test4.png",
    name: "Award4",
    description: "Description4",
  };

  const fourth = {
    img: "../assets/test5.png",
    name: "Award5",
    description: "Description5",
  };

  const fifth = {
    img: "../assets/test6.jpg",
    name: "Award6",
    description: "Description6",
  };
  return (
    <div className="main">
      <div className="container1">
        <h1>Awards & Recognitions</h1>
      </div>
      <div style={{ padding: "20px 60px" }}>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <AwardsSingle content={ground} />
          </SwiperSlide>
          <SwiperSlide>
            <AwardsSingle content={first} />
          </SwiperSlide>
          <SwiperSlide>
            <AwardsSingle content={second} />
          </SwiperSlide>
          <SwiperSlide>
            <AwardsSingle content={third} />
          </SwiperSlide>
          <SwiperSlide>
            <AwardsSingle content={fourth} />
          </SwiperSlide>
          <SwiperSlide>
            <AwardsSingle content={fifth} />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
