import React from "react";
import "../style/privacypolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="side-border left"></div>
      <div className="side-border right"></div>
      <div className="privacy-policy-header">
        <h1>Privacy Policy</h1>
        <p>Last updated: July 21, 2024</p>
      </div>
      <div className="privacy-policy-content">
        <p>
          <strong>Company Name:</strong> DSR Janata Hindu Hotel PVT Ltd.
        </p>
        <p>
          <strong>Website Name:</strong> Dada Boudi Hotel
        </p>
        <p>
          <strong>Phone No:</strong> 8100510647
        </p>
        <p>
          <strong>Email:</strong> dadaboudiofficial@gmail.com
        </p>
        <p>
          <strong>Address:</strong> 1 no. Ghosh Para Road, Barrackpore
        </p>

        <h2>Introduction</h2>
        <p>
          Welcome to Dada Boudi Hotel. We are committed to respecting your
          privacy. This privacy policy explains how we collect, use, and
          safeguard your information when you visit our website. Please read
          this policy carefully to understand our views and practices regarding
          your personal data and how we will treat it.
        </p>

        <h2>Information We Collect</h2>
        <p>
          Our website does not collect any personal information from users. We
          do not use cookies or any other tracking technologies to collect
          information about your visit to our website.
        </p>

        <h2>Use of Information</h2>
        <p>
          Since we do not collect personal information, we do not use any
          personal information for any purposes. Our website is solely for the
          purpose of providing information about our restaurant.
        </p>

        <h2>Third-Party Services</h2>
        <p>
          We do not use any third-party services that collect, monitor, or
          analyze user information on our website.
        </p>

        <h2>Data Security</h2>
        <p>
          We take the security of your information seriously. However, please
          note that no method of transmission over the internet or method of
          electronic storage is 100% secure. While we strive to use commercially
          acceptable means to protect your personal information, we cannot
          guarantee its absolute security.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when they are posted on
          this page.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <p>Email: dadaboudiofficial@gmail.com</p>
        <p>Phone: 8100510647</p>
        <p>Address: 1 no. Ghosh Para Road, Barrackpore</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
