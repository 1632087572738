import React from "react";
import { Link } from "react-router-dom";
import "../style/notFound.css";
import biryaniImage from "../assets/hero-slider-1-png.png";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="side-border left"></div>
      <div className="side-border right"></div>
      <div className="not-found-header">
        <h1>404</h1>
        <h2>Page Not Found</h2>
      </div>
      <div className="not-found-content">
        <img src={biryaniImage} alt="Biryani" className="biryani-image" />
        <p>Sorry, the page you are looking for does not exist.</p>
        <Link to="/" className="home-link">
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
