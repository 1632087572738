import React from "react";
import "../style/aboutUs.css";
import CateringVideo from "./CateringVideo";

const AboutUs = () => {
  return (
    <>
      <div className="menu-container">
        <div className="side-border left"></div>
        <div className="side-border right"></div>
        <div className="menu-header">
          <h1>Dada Boudi Hotel</h1>
          <p>where culinary legacy meets passion</p>
        </div>

        <div className="section-content">
          <p>
            The alluring scent of biryani attracts foodies from every corner to
            the bustling lanes of Barrackpore. Since the late 1980s, biryani
            lovers have flocked to this establishment, a tradition that traces
            back to the teenage years of brothers Sanjib Saha and Rajib Saha.
          </p>
        </div>

        <div className="founder-message">
          <h2>FOUNDER’S MESSAGE</h2>
          <p>
            “Starting from the tag of “DADA BOUDI” to the love, support and
            growth is something “our customers” have immensely blessed us with.
            We as a couple are highly obliged and we look forward to serving you
            with our hearts and souls.”
          </p>
          <p style={{ fontWeight: "bold", marginTop: "20px" }}>
            - DHIREN SAHA & SANDHYA RANI SAHA
          </p>
        </div>

        <div className="history-section">
          <h2>HISTORY</h2>
          <p>
            In 1961, my father migrated from Bihar to Kolkata, where we
            established our first eatery near Barrackpore railway station.
            Initially offering only a daily meal, our eatery remained nameless
            for several years. Nearly 15 years later, I assumed responsibility
            for the eatery, expanding its menu to include a variety of dishes.
            Over time, my wife, Sandhya Saha, joined me in managing the eatery,
            earning us the endearing title of "Dada Boudi" (meaning brother and
            sister-in-law in Bengali). Consequently, our eatery became
            affectionately known as "Dada Boudi."
          </p>
          <p>
            Our sons, Sanjib Saha and Rajib Saha, served as helping hands to us
            since childhood. Years later, they unveiled this tantalizing dish in
            our family's humble 200-square-foot eatery. The beginning was
            back-breaking, but our patience and hard work have led to fame and
            success in the upcoming years.
          </p>
          <p style={{ fontWeight: "bold", marginTop: "20px" }}>
            - DHIREN SAHA & SANDHYA RANI SAHA
          </p>
        </div>
      </div>
      <CateringVideo />
    </>
  );
};

export default AboutUs;
