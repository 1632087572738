import React from "react";
import best3 from "../assets/best3.jpg";
import "../style/bestSeller.css";

function BestSeller3() {
  return (
    <div>
      <img src={best3} alt="best seller images" className="best" />
    </div>
  );
}

export default BestSeller3;
