import React from "react";
import "../style/banquetVideo.css";

const BanquetVideo = () => {
  return (
    <div className="menu-container">
      <div className="side-border left"></div>
      <div className="side-border right"></div>
      <div className="menu-header">
        <h1>Dada Boudi Banquet</h1>
      </div>
      <div className="video-section">
        <h2>Banquet Hall</h2>
        <div className="video-wrapper">
          <iframe
            src="https://www.youtube.com/embed/4-Nn510lnOs"
            title="YouTube Shorts video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default BanquetVideo;
