import React from "react";
import best6 from "../assets/best6.jpg";
import "../style/bestSeller.css";

function BestSeller6() {
  return (
    <div>
      <img src={best6} alt="best seller images" className="best" />
    </div>
  );
}

export default BestSeller6;
