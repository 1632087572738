import React from "react";
import heroPhoto from "../assets/hero-slider-1.jpg";
import heroPhotoCrop from "../assets/hero-slider-crop.jpg";
import "../style/heroSection.css";

function HeroSection() {
  return (
    <div className="hero">
      <img src={heroPhoto} alt="hero image full" className="full" />
      <img src={heroPhotoCrop} alt="hero image cropped" className="cropped" />
    </div>
  );
}

export default HeroSection;
