import React from "react";

import "../style/catering.css";

const CateringContactIndoor = () => {
  return (
    <div className="menu-container">
      <div className="side-border left"></div>
      <div className="side-border right"></div>
      <div className="menu-header">
        <h1>Dada Boudi Catering Service</h1>
      </div>
      <div className="contact-section">
        <h2>Contact Information</h2>
        <div className="contact-item">
          <span>
            <a
              href={`tel:8100510647`}
              style={{ color: "black", textDecoration: "none" }}
            >
              8100510647
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CateringContactIndoor;
