import React from "react";
import { Link } from "react-router-dom";
import "../style/siteMap.css";

const SiteMap = () => {
  return (
    <div className="menu-container">
      <div className="side-border left"></div>
      <div className="side-border right"></div>
      <div className="menu-header">
        <h1>Website Sitemap</h1>
      </div>
      <div className="sitemap-section">
        <h2>Main Pages</h2>
        <div className="sitemap-item">
          <span className="sitemap-icon">➤</span>
          <Link to="/">Home</Link>
        </div>
        <div className="sitemap-item">
          <span className="sitemap-icon">➤</span>
          <Link to="/menu">Menu</Link>
        </div>
        <div className="sitemap-item">
          <span className="sitemap-icon">➤</span>
          <Link to="/about">Our Story</Link>
        </div>
        <div className="sitemap-item">
          <span className="sitemap-icon">➤</span>
          <Link to="/about">Our History</Link>
        </div>
        <div className="sitemap-item">
          <span className="sitemap-icon">➤</span>
          <Link to="/location">Location</Link>
        </div>
        <div className="sitemap-item">
          <span className="sitemap-icon">➤</span>
          <Link to="/awards">Awards</Link>
        </div>
      </div>
      <div className="sitemap-section">
        <h2>Services</h2>
        <div className="sitemap-item">
          <span className="sitemap-icon">➤</span>
          <Link to="/catering">Catering</Link>
        </div>
        <div className="sitemap-item">
          <span className="sitemap-icon">➤</span>
          <Link to="/catering">Banquet</Link>
        </div>
      </div>
    </div>
  );
};

export default SiteMap;
