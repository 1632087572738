import React from "react";
import "../style/menu.css";
import {
  Biriyani,
  OurSignatureDish,
  Salad,
  ChineseDish,
  IndianDish,
  TandoorDish,
  DrinksDesserts,
} from "../utils/MenuItem";

const Menu = () => {
  return (
    <div>
      {/* className="back" */}
      <div className="menu-container" style={{ marginBottom: "20px" }}>
        <div className="side-border left"></div>
        <div className="side-border right"></div>
        <div className="menu-header">
          <h1>Our Signature Dish</h1>
        </div>
        {OurSignatureDish.map((section, index) => (
          <div className="menu-section" key={index}>
            <h2>{section.type}</h2>
            {section.itemDetails.map((item, ind) => (
              <div className="menu-item" key={ind}>
                <span>{item.name}</span>
                <span>{item.price || "N/A"}</span>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="menu-container" style={{ marginBottom: "20px" }}>
        <div className="side-border left"></div>
        <div className="side-border right"></div>
        <div className="menu-header">
          <h1>Chinese Dish</h1>
        </div>
        {ChineseDish.map((section, index) => (
          <div className="menu-section" key={index}>
            <h2>{section.type}</h2>
            {section.itemDetails.map((item, ind) => (
              <div className="menu-item" key={ind}>
                <span>{item.name}</span>
                <span>{item.price || "N/A"}</span>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="menu-container" style={{ marginBottom: "20px" }}>
        <div className="side-border left"></div>
        <div className="side-border right"></div>
        <div className="menu-header">
          <h1>Biriyani</h1>
        </div>
        {Biriyani.map((section, index) => (
          <div className="menu-section" key={index}>
            {/* <h2>{section.type}</h2> */}
            {section.itemDetails.map((item, ind) => (
              <div className="menu-item" key={ind}>
                <span>{item.name}</span>
                <span>{item.price || "N/A"}</span>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="menu-container" style={{ marginBottom: "20px" }}>
        <div className="side-border left"></div>
        <div className="side-border right"></div>
        <div className="menu-header">
          <h1>Salad</h1>
        </div>
        {Salad.map((section, index) => (
          <div className="menu-section" key={index}>
            {/* <h2>{section.type}</h2> */}
            {section.itemDetails.map((item, ind) => (
              <div className="menu-item" key={ind}>
                <span>{item.name}</span>
                <span>{item.price || "N/A"}</span>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="menu-container" style={{ marginBottom: "20px" }}>
        <div className="side-border left"></div>
        <div className="side-border right"></div>
        <div className="menu-header">
          <h1>Indian Dish</h1>
        </div>
        {IndianDish.map((section, index) => (
          <div className="menu-section" key={index}>
            <h2>{section.type}</h2>
            {section.itemDetails.map((item, ind) => (
              <div className="menu-item" key={ind}>
                <span>{item.name}</span>
                <span>{item.price || "N/A"}</span>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="menu-container" style={{ marginBottom: "20px" }}>
        <div className="side-border left"></div>
        <div className="side-border right"></div>
        <div className="menu-header">
          <h1>Tandoor Dish</h1>
        </div>
        {TandoorDish.map((section, index) => (
          <div className="menu-section" key={index}>
            <h2>{section.type}</h2>
            {section.itemDetails.map((item, ind) => (
              <div className="menu-item" key={ind}>
                <span>{item.name}</span>
                <span>{item.price || "N/A"}</span>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="menu-container" style={{ marginBottom: "20px" }}>
        <div className="side-border left"></div>
        <div className="side-border right"></div>
        <div className="menu-header">
          <h1>Drinks & Desserts</h1>
        </div>
        {DrinksDesserts.map((section, index) => (
          <div className="menu-section" key={index}>
            <h2>{section.type}</h2>
            {section.itemDetails.map((item, ind) => (
              <div className="menu-item" key={ind}>
                <span>{item.name}</span>
                <span>{item.price || "N/A"}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
